import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Nextcloud 18: `}<a parentName="li" {...{
          "href": "https://nextcloud.com/blog/the-new-standard-in-on-premises-team-collaboration-nextcloud-hub/"
        }}>{`https://nextcloud.com/blog/the-new-standard-in-on-premises-team-collaboration-nextcloud-hub/`}</a><ul parentName="li">
          <li parentName="ul">{`OnlyOffice`}</li>
          <li parentName="ul">{`Nytt galleri`}</li>
          <li parentName="ul">{`Flow`}</li>
          <li parentName="ul">{`Talk`}</li>
          <li parentName="ul">{`Nextcloud 3.10 (Android/iOS): `}<a parentName="li" {...{
              "href": "https://nextcloud.com/blog/nextcloud-3-10-0-for-android-is-here-bringing-collaborative-tools-dark-mode-rich-workspaces-and-more/"
            }}>{`https://nextcloud.com/blog/nextcloud-3-10-0-for-android-is-here-bringing-collaborative-tools-dark-mode-rich-workspaces-and-more/`}</a><ul parentName="li">
              <li parentName="ul">{`Dark Mode!`}</li>
              <li parentName="ul">{`Redigera textfiler`}</li>
            </ul></li>
        </ul></li>
      <li parentName="ul">{`Krita får finansiering av Epic: `}<a parentName="li" {...{
          "href": "https://krita.org/en/item/krita-receives-epic-megagrant/"
        }}>{`https://krita.org/en/item/krita-receives-epic-megagrant/`}</a></li>
      <li parentName="ul">{`Link-aggregator Lemmy v0.6.0 - `}<a parentName="li" {...{
          "href": "https://github.com/dessalines/lemmy/releases/tag/v0.6.0"
        }}>{`https://github.com/dessalines/lemmy/releases/tag/v0.6.0`}</a><ul parentName="li">
          <li parentName="ul">{`Federation issue: `}<a parentName="li" {...{
              "href": "https://github.com/dessalines/lemmy/issues/145"
            }}>{`https://github.com/dessalines/lemmy/issues/145`}</a></li>
        </ul></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Mozilla säger upp 70 personer: `}<a parentName="li" {...{
          "href": "https://techcrunch.com/2020/01/15/mozilla-lays-off-70-as-it-waits-for-subscription-products-to-generate-revenue/"
        }}>{`https://techcrunch.com/2020/01/15/mozilla-lays-off-70-as-it-waits-for-subscription-products-to-generate-revenue/`}</a></li>
      <li parentName="ul">{`Säkerhetsexperter hittar databas med 267 miljoner användares Facebook-uppgifter: `}<a parentName="li" {...{
          "href": "https://www.comparitech.com/blog/information-security/267-million-phone-numbers-exposed-online/"
        }}>{`https://www.comparitech.com/blog/information-security/267-million-phone-numbers-exposed-online/`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Proton mail/calendar: `}<a parentName="li" {...{
          "href": "https://protonmail.com/blog/protoncalendar-beta-announcement/"
        }}>{`https://protonmail.com/blog/protoncalendar-beta-announcement/`}</a></li>
      <li parentName="ul">{`Inkscape: `}<a parentName="li" {...{
          "href": "https://gitlab.com/inkscape/inkscape"
        }}>{`https://gitlab.com/inkscape/inkscape`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <ul>
      <li parentName="ul">{`PinePhone på väg, kanske?`}</li>
      <li parentName="ul">{`Battle segment nästa vecka`}<ul parentName="li">
          <li parentName="ul">{`PS4 vs Steam controller`}</li>
          <li parentName="ul">{`Skicka in ditt förslag på battles till `}<a parentName="li" {...{
              "href": "mailto:kontakt@trevligmjukvara.se"
            }}>{`kontakt@trevligmjukvara.se`}</a>{` eller i vår telegram-kanal!`}</li>
        </ul></li>
    </ul>
    <h2>{`Feedback`}</h2>
    <ul>
      <li parentName="ul">{`bittin: Skal / Desktop Environment / Skrivbordsmiljö`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Cursed Intro - Dark Dramatic Epic Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`License for all: CC BY (`}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a>{`)`}</li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      